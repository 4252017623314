<template>
    <card-general>
        <leave-interface-switch/>

        <div class="inner-green-block bg-primary-50 px-2 px-sm-3 px-md-5 py-4 bento-border-radius">

            <MDBSelect
                ref="leaveTypeSelect"
                v-model:options="leaveTypes"
                v-model:selected="selectedLeaveTypeOption"
                @change="selectChangeHandler"
                size="lg"
                class="mb-4"
                id="leave-type-dropdown-selector"
                :visibleOptions="10"
            />
            <div v-if="isEnabledLeaveShowAnnualBalanceBreakdown && isAnnualLeaveTypeSelected" class="mt-n4 ps-2 mb-3 pt-2">
                <div>
                    <div class="font-size-13 fw-500 mt-n2 mb-1 gradient-green-text-dark">* Days you have remaining this year.</div>
                    <a class="gradient-green-text-dark cursor-pointer hover-primary mb-1 font-weight-500" @click="scrollToHash('annual-balance-summary', 90)"><i class="fas font-size-16 fa-info-circle cursor-pointer"></i> View your annual balance summary below -></a>
                </div>
            </div>

            <div v-if="false">
                <pre>{{ leaveTypes }}</pre>
            </div>

            <leave-calendar-interface/>
        </div>

        <leave-annual-balance-summary v-if="isEnabledLeaveShowAnnualBalanceBreakdown" class="mt-4" id="annual-balance-summary"/>

        <leave-calendar-type-notices :leave-requests-for-this-type="leaveRequestsForThisType" v-if="false"/>
    </card-general>
</template>

<script setup>
import {useStore} from "vuex"
import {MDBSelect} from "mdb-vue-ui-kit"
import {ref, onMounted, computed} from "vue"
import LeaveCalendarInterface
    from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveCalendarInterface.vue"
import {roundToTwoDecimal} from "@/hooks/general/currency-number-formatters"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue";
import LeaveInterfaceSwitch
    from "@/components/benefits-leave-reimbursements/leave/components/LeaveInterfaceSwitch.vue";
import LeaveCalendarTypeNotices
    from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveCalendarTypeNotices.vue";
import {isValidArray} from "@/hooks/general/type-and-value-checks";
import LeaveAnnualBalanceSummary
    from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveAnnualBalanceSummary.vue";
import {scrollToHash} from "@/hooks/general/navigation"

const store = useStore()

const props = defineProps({
    selectedLeaveTypeFromBalances: {
        type: Object,
        required: false,
    }
})

const isEnabledLeaveShowAnnualBalanceBreakdown = store.getters.isEnabledLeaveShowAnnualBalanceBreakdown

const selectedLeaveType = computed(() => store.getters.selectedLeaveType)
const leaveRequestList = computed(() => store.getters.leaveRequestList)
const leaveRequestsForThisType = computed(() => {
    if (!isValidArray(leaveRequestList.value)) {
        return false
    }

    const unsorted = leaveRequestList.value;
    const sortedByDate = unsorted.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
    const leaveTypeId = Number(selectedLeaveType.value.id)
    return sortedByDate.filter(request => Number(request.leave_type) === leaveTypeId)
})

const leaveBalancesSorted = store.getters.leaveBalancesSorted
const leaveTypeSelect = ref(null) // used to reference the select so I can use methods on it. (v-model property below is read only)

const cleanName = (name) => name.replace(/Leave/g, '').trim()

// leave days left calculation
const annualLeaveObj = computed(() => leaveBalancesSorted.find(leaveObj => leaveObj.name.toLowerCase().includes('annual')))
const currentBalance = computed(() => roundToTwoDecimal(annualLeaveObj.value?.balance) || 0)

const leaveRequestsThisYear = computed(() => {
    const currentYear = new Date().getFullYear()
    const thisYearRequests = leaveRequestList.value.filter(request => {
        const startDate = new Date(request.start_date)
        return startDate.getFullYear() === currentYear
    })
    return thisYearRequests.filter(request => request.leave_type_name.toLowerCase() === 'annual')
})

const approvedLeaveRequestsThisYear = computed(() => {
    return leaveRequestsThisYear.value.filter(request => request.state === 'approved')
})

const totalApprovedDaysThisYearFuture = computed(() => {
    const currentDate = new Date()
    const futureApprovedRequests = approvedLeaveRequestsThisYear.value.filter(request => new Date(request.start_date) > currentDate)
    return futureApprovedRequests.reduce((acc, request) => acc + request.days, 0)
})

const leaveDaysLeftInYear = computed(() => {
    return roundToTwoDecimal(currentBalance.value - totalApprovedDaysThisYearFuture.value)
})

const textOutput = (leaveObj) => {
    let balance = ''
    console.log(isEnabledLeaveShowAnnualBalanceBreakdown)
    if (isEnabledLeaveShowAnnualBalanceBreakdown && leaveObj.name.toLowerCase().includes('annual')) {
        const number = leaveDaysLeftInYear.value
        if (number === 1) {
            balance = `• ${number} day```
        } else {
            balance = `• ${number} days`
        }
    }
    else if (
        !(leaveObj.name.toLowerCase().includes('parental') ||
            leaveObj.name.toLowerCase().includes('maternity') ||
            leaveObj.name.toLowerCase().includes('paternity') ||
            leaveObj.name.toLowerCase().includes('parent'))
    ) {
        const number = roundToTwoDecimal(leaveObj.balance)
        if (number === 1) {
            balance = `• ${number} day`
        } else {
            balance = `• ${number} days`
        }
    }
    const name = cleanName(leaveObj?.type?.description || leaveObj.name)
    return name + ' ' + balance
}

const optionsArr = leaveBalancesSorted.map(leaveObj => {
    return {
        text: textOutput(leaveObj),
        value: leaveObj.id
    }
})
const leaveTypes = ref(optionsArr) // used v-model of the select options. (v-model needs a reactive property)
const selectedLeaveTypeOption = ref("") // used for v-model of user selected options. (v-model needs a reactive property). Set from Balances Interface.

const isAnnualLeaveTypeSelected = computed(() => {
    const selectedLeaveTypeObj = leaveBalancesSorted.find(leaveTypeObj => leaveTypeObj.id === selectedLeaveTypeOption.value)
    return selectedLeaveTypeObj?.type?.name.toLowerCase().includes('annual')
})

const selectChangeHandler = () => {
    const dropdownSelectedLeaveType = leaveBalancesSorted.find(leaveTypeObj => leaveTypeObj.id === selectedLeaveTypeOption.value)
    store.commit('setSelectedLeaveType', dropdownSelectedLeaveType)
}

onMounted(() => {
    if (props.selectedLeaveTypeFromBalances) {
        leaveTypeSelect.value.setValue(props.selectedLeaveTypeFromBalances.id) // setting from 'Balances' view
    }
})

</script>

<style scoped lang="scss">
@import "@/styles/global-scss/variables-and-mixins.scss";

.inner-green-block {
    border: 1px solid rgba($card-border-color, .3);
}

:deep(#leave-type-dropdown-selector) {
    border-radius: 13px;
    input {
        border-radius: 13px;
        background-color: white;
        color: black;
        font-weight: 500;
        font-size: 16px;
        padding: 12px 15px;
    }
    .form-notch-leading {
        border-bottom-left-radius: 13px;
        border-top-left-radius: 13px;
    }
    .form-notch-trailing {
        border-bottom-right-radius: 13px;
        border-top-right-radius: 13px;
    }
    span.select-arrow {
        top: 22px;
    }
    .form-notch {
        > div {
            border-color: $card-border-color-light;
        }
    }
}
</style>